import * as React from "react";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export default function ColorCheckboxes() {
  return (
    <div>
      <Checkbox {...label} defaultChecked />
      <Checkbox
        {...label}
        defaultChecked
        color="secondary"
        sx={{
          color: pink[800],
        }}
      />
      <Checkbox {...label} defaultChecked color="success" />
      <Checkbox defaultChecked color="default" />
      <Checkbox
        {...label}
        defaultChecked
        sx={{
          color: pink[800],
          "&.Mui-checked": {
            color: pink[600],
          },
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox defaultChecked iconStyle={{ fill: "white" }} />}
          label={<span style={{ color: "white" }}>Label</span>}
        />
        <FormControlLabel
          control={
            <Checkbox
              {...label}
              defaultChecked
              sx={{
                color: pink[800],
                "&.Mui-checked": {
                  color: pink[600],
                },
              }}
            />
          }
          label={<span style={{ color: "red" }}>Disabled</span>}
        />
        <FormControlLabel
          control={<Checkbox name="checkedC" />}
          label="Remember Password"
        />
      </FormGroup>
    </div>
  );
}
